<template>
  <div />
  <!--
    moment.localeの設定をmain.jsで一括処理する
     とりあえずpushしたが未確認
  -->
  <!--
    Storageへの処理を分離する
  -->
  <!--
  サブコレクションの使い方がわかった
  https://zenn.dev/google_cloud_jp/articles/a0a6b5f855fe90
  -->
  <!--
    helper/functionのfunciton, user, mailを整理すること
  -->
  <!--
    セキュリティルールを考える
  -->
  <!--
    Vue 3, Vite移行
  -->
</template>

<script>

export default {
  name: 'TheSpace',
  components: {},
  data() {
    return {}
  },
  computed: {
    showLoadingMask: function () {
      return this.$store.getters.showLoadingMask
    },
  },
  methods: {},
}
</script>
